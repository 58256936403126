import './SignUp.scss';

function SignUp () {

    return (
        <section className='sign-up'>

        </section>
    )
}

export default SignUp